var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        size: "normal",
        title: "选择打卡地址",
        closable: true,
        "overflow-auto": "",
        visible: _vm.show,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        [
          _c("custom-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataSource,
              "row-key": "id",
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                columnWidth: 50,
                onChange: _vm.onSelectChange,
              },
              "selection-mode": true,
              pagination: false,
              scroll: { y: 600 },
            },
            on: { change: _vm.loadDataList },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }